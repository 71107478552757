html, body {
  margin: 0;
  padding: 0;
  background-color: #121212;
  color: whitesmoke;
}
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}